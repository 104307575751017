<template>
  <div class="">
    <titleBar
      :title="barTitle"
      back="auto"
      :showBackArrowDesktop="true"
      subtitle=""
      :centered="false"
      theme="white"
      :showSignup="false"
      containerClass="donotuse"
      :showDesktop="true"
      :showAfterScroll="0"
      :scrollTopOnClick="true"
      :inline="false"
      actionButtonStyle="small"
      actionButtonRoundBg="transparent"
      iconPack="far"
    />

    <div class="section">
      <div class="container promoMenu" style="">
        <div style="text-align: center">
          <h2 class="title is-2">{{ barTitle }}</h2>
          <h2 class="subtitle is-4">
            <!-- DM friendly bots engineered for specific tasks.
             -->
          </h2>
          <br />
        </div>

        <!-- featuredTags loop over
        <div class="tags" style="text-align: center">
          <router-link
            v-for="tag in featuredTags"
            :key="tag"
            :to="'/tag/' + tag"
            class="tag is-medium is-link is-light"
            style="margin: 0.5rem"
          >
            {{ tag }}
          </router-link>
        </div> -->

        <bot-list v-if="items.length" :bots="items" :page-size="50" :paginate="true"></bot-list>
        <empty v-if="!items.length && !loading" title="Nothing to show here yet..." img="shrug" />
        <!--  
      <div class="columns is-multiline is-mobile">
        <div class="column is-6-mobile is-4-tablet is-4-desktop is-3-fullhd" v-for="b in bots" :key="b.a">
          <router-link :to="'/page/' + b.handle">
            <div class="profileCard">
              <img :src="b.avatar" loading="lazy" style="" />
              <header class=" ">
                <h4 class="title is-4">
                  {{ b.handle }}
                </h4>
              </header>
              <p class="desc" v-html="b.bio"></p>
            </div>
          </router-link>
        </div>
      </div>-->

        <div v-show="false">
          <promoMenu />
        </div>
      </div>
      <hr />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
//import projCard from "@/components/projCard.vue";
import waitlist from "@/components/waitlist.vue";
import qrform from "@/components/re/qrform.vue";

import guide from "@/components/re/guide.vue";
import promoMenu from "@/components/re/promoMenu.vue";

import BotList from "@/components/BotList.vue";
import FullPageSearch from "@/components/e/fullPageSearch.vue";

//import pricing
import pricing from "@/components/pricing.vue";
const keyFeatures = [
  {
    title: "Chat with bots 💬",
    description: "Ask them questions, learn about specific topics, and discover fun personalities.",
  },

  {
    title: "Follow AI creators  👀", //🖼️  look emoji:  👀
    description: " Follow projects and creators you like. Discover the best AI content on the web.",
  },

  {
    title: "Launch your bot 🚀", //🤖
    description:
      " For your brand, or just for fun. " +
      "   Define its purpose, its personality and how it interacts online." +
      " Creators can earn money from their bots.",
  },
];

const featuredTags = ["homework", "fitness", "ai", "coding", "fun", "charachters", "education", "bot"];

export default {
  name: "Home",
  components: {
    BotList,
    // FullPageSearch,
    // qrform, //
    //  waitlist,
    //  guide,
    //  promoMenu,
    // pricing,
    //  HelloWorld,
    // projCard,
  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Explore  ",
    // all titles will be injected into this template
    // titleTemplate: "%s  🍔  ",
    //meta: [{ charset: "utf-8" }, { name: "viewport", content: "width=device-width, initial-scale=1" }],
  },
  data() {
    return {
      featuredTags,
      bots: [],
      keyFeatures,
      projs: [],
      loading: true,
      items: [],
      // loadingOps: true,
    };
  },
  methods: {
    //  getBots() {
    //    window.API.getBots().then((proj) => {
    //      this.bots = proj;
    //      this.loadingBots = false;
    //      // console.log("PROJJ!", proj, proj.id);
    //    });
    //  },
    load() {
      //depending on routename, determine the right method to call
      var rt = this.$route.name;
      const API = window.API;
      this.loading = true;
      let promise;
      if (rt == "botFollowers") {
        promise = API.getBotFollowers(this.$route.params.bot);
      } else if (rt == "botFollowing") {
        promise = API.getTag(this.$route.params.bot);
      } else if (rt == "userFollowers") {
        promise = API.getUserFollowers(this.$route.params.userId);
      } else if (rt == "userFollowing") {
        promise = API.getUserFollowing(this.$route.params.userId);
      } else if (rt == "userInvited") {
        promise = API.getUserInvited(this.$route.params.userId);
      }

      if (!promise) return alert("missing configuration for this route in Followers.vue");

      promise.then((iii) => {
        this.items = iii;
        this.loading = false;
        // console.log("PROJJ!", proj, proj.id);
      });

      //  this.getBots();
    },
  },
  mounted() {
    this.load();
  },
  computed: {
    rt() {
      return this.$route.name;
    },
    barTitle() {
      if (this.rt.includes("Followers")) return "Followers";
      if (this.rt.includes("Invited")) return "Invited";

      return "Following";
      return this.rt + "*****";
      return this.$route.params.search || "Explore";
    },
  },
};
</script>
